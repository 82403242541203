.main {
    position: relative;

    background-color: white;
    margin: 0 auto;
    z-index: 1;
    max-width: 1300px;
    width: 90%;
}
.main .pageHeader {
    font-size: 38px;
    color: #2468b2;
    width: 90%;
    text-wrap: normal;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 10px;
}

.main .date {
    margin: 0 auto;
    width: 90%;
    padding-bottom: 10px;
    color: #d1d1d2;
}


.newsPage {
    width: 90%;
    max-width: 850px;
    color: #606260;
    font-family: "Proxima Nova Lt";
    font-size: 17px;
    margin: 0 auto;
    line-height: 1.5;
    padding-bottom: 20px;
}

.newsPage a {
    font-family: "Proxima Nova Lt";
    text-decoration-line: none;
    cursor: pointer;
    color: #16318a;
    font-size: 17px;
}

.newsPage a:hover {
    text-decoration-line: underline;
}

.newsPage .newsImg {
    max-width: 100%;
    margin-bottom: 20px;
}
.newsPage .anotherImg {
    max-width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
}

.newsPage .anotherBigImg {
    max-width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
}


.newsPage .newsImg img{
    /*border: #bbbbb9 1px solid;*/
    border-radius: 20px;
    display:block;
    margin:auto;
    max-width: 100%;
}
.newsPage .anotherImg img{
    border-radius: 20px;
    display:block;
    margin:auto;
    max-width: 100%;
    transition: 0.3s ease-in-out;
}

.newsPage .anotherBigImg img{
    scale: 2;
    border-radius: 20px;
    display:block;
    margin:auto;
    max-width: 100%;
    transition: 0.3s ease-in-out;
}

.shareBlock {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: #dadfe9 1px solid;
    margin-top: 30px;
    padding-top: 40px;
    margin-bottom: 40px;
}



.shareBlock .btn a {

    color: #153089;
    font-size: 17px;
    text-decoration-line: none;


}

.shareBlock:hover .btn a {
    color: white;
}


.shareBlock .btn {
    color: #153089;
    display: block;
    padding: 5px 15px;
    box-shadow: 0 4px 11px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-family: 'Proxima Nova Rg';
    background-color: #fff;
    border-radius: 10px;
    transition: 0.2s linear;
}

.shareBlock .btn:hover {
    background-color: #153089;
    color: white;
}

.shareBlock .btn .arrow{
    color: #f7ab43;
    padding-right: 10px;
}

.shareBlock .btn:hover .arrow{
    color: white;
    fill: white;
}

.share {

    display: flex;
    flex-wrap: wrap;
    font-size: 17px;
    font-family: "Segoe UI";
    color: #d1d1d2;
    align-items: center;

}

.share .icons {
    display: flex;
    padding-left: 10px;
}

.share .icons .item {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin: 2px;
}

.share .icons .item img{
    height: 12px;
}

.advice {
    width: 100%;
    border-top: #dadfe9 1px solid;
    padding-bottom: 40px;
}

.advice .sovetuem {
    width: 90%;
    font-size: 38px;
    color: #2468b2;
    text-wrap: normal;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
}

.adviceList {
    width: calc(100% - 60px);
    max-width: 95vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin-left: 70px;
    margin-right: 70px;
    gap: 30px;
}


.newsSquare .newsImg img{
    max-height: 260px;
    width: 100%;
    object-fit: cover;
}

.newsSquare {
    max-width: calc(33% - 40px);
    min-width: 330px;
    display: block;
    transition: all 0.3s ease;
}

.newsSquare:hover {
    transform: scale(1.05);
}


.newsSquare .newsTitle a{
    padding-top: 5px;
    font-size: 20px;
    color: #2468b2;
    text-decoration-line: none;
    max-width: 400px;
    min-width: 160px;
    max-height: 65px;
    display: block;
}

.newsSquare .newsTitle {
    display: inline-block;
    max-height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.newsSquare .description {
    color: #333333;
    font-size: 17px;
    text-wrap: normal;
    text-overflow: ellipsis;
    margin-top: 7px;
}

.newsSquare .date {
    color: #484848;
    font-size: 14px;
}

.newsSquare .type {
    padding-left: 36px;
    color: #054982;
    font-size: 14px;
}

.newsSquare .btn a {
    fill: #054982;
    color: #054982;
    display: inline-block;
    border-radius: 10px;
    padding: 10px 12px;
    margin-top: 10px;
    box-shadow: 0 4px 11px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    text-decoration-line: none;
    background-color: white;
}

.newsSquare .btn:hover a {
    fill: white;
    color: white;
    background-color: #2468b2;
}

@media screen and (max-width: 1251px) {
    .newsSquare {
        max-width: calc(50% - 30px);
    }
}

@media screen and (max-width: 850px) {
    .newsSquare {
        max-width: 90%;
    }
}

.newsFileRed {
    margin: 0 auto;
}

.newsFileRed a {
    display: inline-block;
    color: #fff;
    border-color: #ff675b;
    background-color: #ff675b;
    cursor: pointer;
    font-size: 14px;
    padding: 14px 20px;
    border-radius: 5px;
}

.newsFileRed a:hover {
    text-decoration-line: none;
}

.main h1 {
    padding-top: 30px;
    padding-bottom: 20px;
    font-size: 34px;
    color: #2468b2;
    width: 90%;
    margin: 0 auto;
    max-width: 1170px;
    font-weight: bolder;
}

.fileBlock {
    display: inline-block;
    padding: 14px;
    cursor: pointer;
    background-color: #08c;
    border: #2468b2 1px solid;
    border-radius: 10px;
}

.fileBlock:hover {
    background-color: #2468b2;
}

.fileBlock a {
    text-decoration-line: none;
    color: white;
}