.carousel {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 25px;
    flex-wrap: wrap;
}

.item {
    flex-basis: 15%;
    max-width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item a {
    text-decoration-line: none;
    display: flex;
    align-items: center;
}

.item a img {
    max-width: 100%;
    max-height: 180px;
}

@media screen and (max-width: 1000px) {
    .carousel {
        row-gap: 30px;
    }

    .item {
        flex-basis: 30%;
        max-width: 30%;
    }
}