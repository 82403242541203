.loginForm {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #c9c9c9;
    border-radius: 20px;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    margin: 0 auto;
    max-width: 400px;

}

.loginForm a {
    color: #cb2d5f;
    text-decoration-line: none;
    font-weight: bold;
}

.loginForm h1 {
    color: black;
    width: 100%;
    font-size: 28px;
    text-align: center;
}

.loginForm .loginInfo {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.loginForm .loginInfo .wrong {
    color: red;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.loginForm .loginInfo .registration {
    font-size: 18px;
    text-align: center;

}

.center {
    display: flex;
    justify-content: center;
}

.loginForm .loginInfo .registration a {
    font-weight: bold;
    color: #000000;

}

.loginForm .loginInfo input[type=username] {
    font-size: 21px;
    min-height: 40px;
    border-radius: 15px;
    border: 1px solid #c9c9c9;
    padding: 5px 15px;
    margin-bottom: 15px;
}

.loginForm .loginInfo input[type=email] {
    font-size: 21px;
    min-height: 40px;
    border-radius: 15px;
    border: 1px solid #c9c9c9;
    padding: 5px 15px;
    margin-bottom: 15px;
}

.loginForm .loginInfo input[type=password] {
    font-size: 21px;
    min-height: 40px;
    border-radius: 15px;
    border: 1px solid #c9c9c9;
    padding: 5px 15px;
    margin-bottom: 15px;
}

.loginForm .loginInfo button {
    border: none;
    font-size: 21px;
    min-height: 40px;
    border-radius: 15px;
    color: white;
    background-color: #2468b2;;
    padding: 5px 15px;
    margin-top: 40px;
    margin-bottom: 15px;
    cursor: pointer;
}

.loginForm .loginInfo label {
    font-size: 21px;
    margin-bottom: 10px;
}
