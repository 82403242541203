.carouselContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    min-height: 360px;
}

.carouselContainer img {
    width: 100%;

}

.carouselWrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.carouselContentWrapper {
    overflow: hidden;
    width: 90%;
    height: 100%;
    margin: 0 auto;
}

.carouselContent {
    display: flex;
    margin: 0 auto;
    transition: all 250ms linear;
    -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
    scrollbar-width: none; /* hide scrollbar in Firefox */

}

/* hide scrollbar in webkit browser */
.carouselContent::-webkit-scrollbar, .carouselContent::-webkit-scrollbar {
    display: none;
}

.carouselContent > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.carouselContent.show3 > * {
    width: 30%
}

.carouselContent.show4 > * {
    width: 22%
}

.leftArrow, .rightArrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: transparent;
    border: none;
    padding: 0;
}

.rightArrow svg path {
    stroke: #005090;
}
.leftArrow svg path {
    stroke: #005090;
}

.leftArrow {
    left: 0;
}

.rightArrow {
    right: 0;
}

.item {
    width: 100%;
    min-height: 340px;
    height: auto;
    display: flex;
    padding-bottom: 10px;
    flex-direction: row;
    column-gap: 4%;
}

.item img {
    width: 48%;
}

.item .newsText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    width: 50%;
    max-width: 50%;
}

.item .newsFlex .newsTitle {

}

.item .newsFlex .newsTitle a {
    max-height: 120px;
    max-height: 120px;
    color: #005090;
    font-size: 28px;
    text-decoration-line: none;
    width: 100%;
    overflow: hidden;
    text-wrap: normal;
    display: flex;
}

.item .newsFlex .newsDate {
    height: 35px;
    padding-top: 15px;
    font-size: 14px;
    color: #00000080
}

.item .newsFlex .newsDescription {
    margin-bottom: 15px;
    height: 125px;
    font-size: 17px;
    color: #333333;
    overflow: hidden;
    text-wrap: normal;
}

.item .btn a {
    fill: #054982;
    color: #054982;
    display: inline-block;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    padding: 10px 12px;
    max-height: 30px;

    box-shadow: 0 4px 11px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    text-decoration-line: none;
    background-color: white;
}

.item .btn a:hover {
    fill: white;
    color: white;
    background-color: #2468b2;
}

@media screen and (max-width: 1000px) {
    .item {
        flex-direction: column;
    }

    .item img {
        width: 100%;
    }

    .carouselContentWrapper {
        width: calc(100% - 100px);
    }

    .item .newsFlex .newsDescription {
        height: auto;
    }

    .item .newsText {
        width: 100%;
        max-width: 100%;
        justify-content: flex-start;
    }
}