.main {
    position: relative;
    height: 100%;
    max-width: 1300px;
    background-color: white;
    margin: 0 auto;
    width: 90%;
    z-index: 1;
    min-height: calc(100vh - 302px);
}

.mainImg {
    width: 90%;
    padding-top: 30px;
    margin: 0 auto;
}

.mainImg img {
    margin-left: 5%;
    width: 90%;
}

.blueBanner {
    margin: 0 auto;
    max-width: 1170px;
    width: calc(90% - 92px);
    border: 1px solid #dadfe9;
    display: block;
    flex-direction: column;
    padding: 25px 45px 30px;
    border-radius: 3px;
    background-color: #eaeef9;
    position: relative;
    z-index: 2;
    background-repeat: no-repeat;
    background-position: 100% 50%;
    background-size: contain;
    background-image: url("../../assets/Main/firstMainBlock.webp");

}

.blueBanner .title {
    line-height: 1;
    color: #3468b2;
    font-size: 36px;
    font-family: "Corbel";
    position: relative;
    width: 80%;
}

.blueBanner p {
    font-size: 17px;
    color: #333;
    font-family: 'Proxima Nova Lt';
    padding: 20px 0;
}

.blueBanner .btn a {
    fill: #054982;
    color: #054982;
    display: inline-block;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    padding: 10px 12px;
    max-height: 24px;

    box-shadow: 0 4px 11px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    text-decoration-line: none;
    background-color: white;
}

.blueBanner .btn a:hover {
    fill: white;
    color: white;
    background-color: #2468b2;
}

.newsRef {
    display: flex;
    width: 90%;
    padding: 25px 0px 30px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    color: #3468b2;
    font-size: 38px;

}

.newsRef a {
    font-size: 17px;
    color: #3468b2;
    text-decoration-line: none;
    padding: 5px;
}

.newsRef a:hover {
    text-decoration-line: underline;
}

.main h1 {
    padding-top: 30px;
    padding-bottom: 20px;
    font-size: 34px;
    color: #2468b2;
    width: 90%;
    margin: 0 auto;
    max-width: 1170px;
}

.contacts {
    margin: 0 auto;
    width: 90%;
    max-width: 1300px;
    text-align: left;
    color: #333333;
}


.contacts li {
    list-style: none;
    border-bottom: 1px solid #dadfe9;
    padding-bottom: 30px;
    padding-top: 30px;
    padding-left: 10px;
}

.contacts li a {
    text-decoration: none;
    color: #333333;
    font-size: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contacts img {
    height: 40px;
    color: #3a4663;
    width: 40px;
    margin-right: 20px;
}

@media screen and (max-width: 1000px) {


}

.navigatorImg a img {
    width: 100%;
}

.navigatorImg {
    margin: 0 auto;
    width: 90%;
}

.reestrBetaDiv {
    margin: 0 auto;
    width: 90%;
}

.reestrBeta {
    background-color: #16318a;
    word-break: normal;
    text-wrap: normal;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 64px;
    margin: 0 auto 40px;
    border-collapse: collapse;
}

.reestrBeta th {
    padding-top: 7px;
    padding-left: 7px;
    text-align: left;
    color: white;

    border: 1px solid #dadfe9;
    display: block;
}

.partnersList {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin: 0 auto;
    gap: 0.5%;
}

.partnersSquare {
    flex-basis: 33%;

    min-width: 30%;
    max-width: 90%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: space-between;
}

.partnersSquare:hover {
    background-color: #f3f4f9;
}


.partnersSquare .partnerImg {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}

.partnersSquare .partnerImg img {
    max-height: 140px;
    max-width: 210px;
}

.partnersSquare .name {
    margin-left: 5%;
}

.partnersSquare .name a {
    display: inline-block;
    width: 90%;
    font-size: 16px;
    text-decoration-line: none;
    color: #2468b2;
    text-align: center;
    padding: 5px 15px 40px;
    margin: 0 auto;
}

@media screen and (max-width: 1251px) {
    .partnersSquare {
        flex-basis: 49%;
    }
}

@media screen and (max-width: 850px) {
    .partnersSquare {
        flex-basis: 90%;
    }
}

.rNews {
    width: 90%;
    margin: 0 auto;
}

.rNews ol {
    padding: 0;
}

.rNews li {
    list-style-position: inside;
}

.rNews li::marker {
    font-size: 25px;
}

.rNews li a {
    font-size: 25px;
    color: #005090;
    text-decoration-line: none;
    text-wrap: normal;
}

.rNews li a:hover {
    text-decoration-line: underline;
}

.about {
    color: #484848;
    font-size: 20px;
    width: 90%;
    margin: 0 auto;
}

.about li {
    padding-bottom: 10px;
}

.imageSlider {
    height: 100%;
    position: relative;
}

.support {
    width: 90%;
    color: #2468b2;
    font-size: 20px;
    margin: 0 auto;
    font-family: "Calibri Light";
}

.support select {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #2468b2;
    padding-left: 10px;
    margin-bottom: 30px;
}


.support input {
    width: calc(100% - 13px);
    height: 50px;
    border-radius: 10px;
    border: 1px solid #2468b2;
    padding-left: 10px;
    margin-bottom: 30px;
}

@media screen and (max-width: 1000px) {
    .support input {
        height: 40px;
    }
}

.support input select {
    font-size: 14px;
}

.support textarea {
    margin-bottom: 30px;
    padding-left: 10px;
    border: 1px solid #2468b2;
    font-size: 20px;
    font-family: "Calibri Light";
    max-width: calc(100% - 14px);
}


input[type=file] {
    width: 250px;
    max-width: 90%;
    color: #444;
    padding: 5px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #2468b2;
    margin-top: 5px;
}

input[type=submit] {
    background-color: white;
    width: 262px;
    height: 40px;
    border: 1px solid #2468b2;
    color: #2468b2;
    cursor: pointer;
}

input[type=file]::file-selector-button {
    margin-top: 7px;
    margin-left: 5px;
    margin-right: 20px;
    border: none;
    background: #2468b2;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
    background: #0d45a5;
}

.calendarBlock {
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    font-size: 20px;
    color: #333333;
    gap: 15px;
}

.calendarBlock .filter {
    border-radius: 5px;
    padding-top: 20px;
    width: 20%;
}

.calendarBlock .desc {
    width: calc(35% - 15px);
    max-width: 100%;
    border: 1px solid rgb(128, 128, 128);
    border-radius: 5px;
    padding-top: 20px;
    margin-right: 40px;
    text-align: center;
}

.clndr {
    width: calc(40% - 15px);
    max-width: 100%;
    min-width: 300px;
}



@media screen and (max-width: 950px) {
    .calendarBlock .desc {
        width: 70%;
    }
    .calendarBlock .clndr {
        margin-left: calc(30% - 8px);
    }
}

@media screen and (max-width: 705px) {
    .calendarBlock .desc {
        width: 100%;
        margin-right: 0;
    }

    .calendarBlock .filter {
        width: 100%;
    }

    .calendarBlock {
        justify-content: center;
    }

    .calendarBlock .clndr {
        margin-left: 0;
    }
}

.hotline {
    margin: 0 auto;

    width: calc(90% - 74px);
    border: 1px solid #dadfe9;
    display: block;
    flex-direction: column;
    padding: 25px 45px 30px;
    border-radius: 3px;
    background-color: rgba(234, 238, 249, 0.7);
    position: relative;
    z-index: 2;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center right;
    /*background-image: url("../../assets/Main/HotlineCallback.png");*/

}

.hotline .title {
    line-height: 1;
    color: #3468b2;
    font-size: 36px;
    font-family: "Corbel";
    position: relative;
    width: 80%;
}

.hotline p {
    font-size: 17px;
    color: #333;
    font-family: 'Proxima Nova Lt';
    padding: 20px 0;
}

.hotline .btn {
    display: block;
}

.hotline .btn a {
    fill: #054982;
    color: #054982;
    display: inline-block;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    padding: 10px 12px;
    max-height: 42px;

    box-shadow: 0 4px 11px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    text-decoration-line: none;
    background-color: white;
}

.hotline .btn a:hover {
    fill: white;
    color: white;
    background-color: #2468b2;
}

@media screen and (max-width: 1037px) {
    .calendarBlock .desc {
        min-width: 50%;
    }
    .calendarBlock {
        justify-content: space-between;
    }
    .hotline {
        background-size: cover;
    }
    .hotline p {
        text-shadow: 1px 1px 1px white;
    }
}

.metodMat {
    width: 90%;
    max-width: 1300px;
    margin: 0 auto;
    padding-top: 50px;
}

.metodMat a {
    font-size: 24px;
    text-decoration-line: none;
    color: #2468b2;
    padding-bottom: 36px;
}

.metodMat a:hover {
    text-decoration-line: underline;
}

.metodMat .btn a {
    text-decoration-line: none;
    margin-top: 100px;
    display: block;
    padding: 5px 15px;
    color: #2468b2;
    font-size: 17px;
    background-color: #fff;
    box-shadow: 0 4px 11px rgba(0, 0, 0, 0.2);
    transition: 0.2s linear;
    border-radius: 10px;
    width: max-content;
}

.metodMat .btn a:hover {
    background-color: #16318a;
    color: #ffffff;
}

.navigator3 {
    overflow-x: scroll;
}

/*.navigator3 .col1 {*/
/*    width: 105px;*/
/*}*/

/*.navigator3 .col2 {*/
/*    width: 185px;*/
/*}*/

/*.navigator3 .col3 {*/
/*    width: 157px;*/
/*}*/

/*.navigator3 .col4 {*/
/*    width: 102px;*/
/*}*/

/*.navigator3 .col5 {*/
/*    width: 176px;*/
/*}*/

/*.navigator3 .col6 {*/
/*    width: 178px;*/
/*}*/

/*.navigator3 .col7 {*/
/*    width: 102px;*/
/*}*/

/*.navigator3 .col8 {*/
/*    width: 215px;*/
/*}*/

.navigator3 th {

    color: white;
    font-weight: 500;
    padding: 5px;

}
.navigator3 details {
    border: 2px solid #eff1f7;
    display: grid;
    grid-template-columns: 9fr 9fr 6fr 3fr 6fr 7fr 5fr 15fr;
    margin: 0 auto;
    font-size: 18px;
    background-color: #f8f9fb;
    white-space: normal;
    word-break: break-all;
    border-top: none;
    width: 1298px;
    max-width: 1298px;
}

.navigator3 th:nth-child(8) {
    border-right: none;
}

.navigator3 summary {
    width: 1300px;
    display: inline-block;
    min-width: 100%;
    background-color: #96b7da;
    padding-top: 2px;
    padding-bottom: 2px;
    color: white;
}

.navigator3 tr {
    font-size: 12px;
    display: grid;
    width: 1300px;
    grid-template-columns: 9fr 9fr 6fr 3fr 6fr 7fr 5fr 15fr;
}

.navigator3 table {
    border-collapse: collapse;

}

.navigator3 td {
    padding: 5px;
    border: 1px solid #aaa;
}



.zagolovok table {
    width: 1300px;
    max-width: 1300px;

    border-collapse: collapse;
}
.zagolovok th {
    background-color: #3468b2;
    border-right: 1px #aaa;
}

.greyBlock {
    max-width: 1200px;
    background: #2468b2;
    color: #ffffff;
    font-size: 28px;
    margin: 0 auto;
    width: 90%;
    overflow-x: auto;
    border-radius: 20px;
}

.greyBlock .item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    padding-bottom: 20px;
    margin: 0 auto;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.region {
    text-align: center;
    cursor: pointer;
    font-size: 28px;
    background-color: #96b7da;
    width: 100%;
}
.lockationsContainer {
    overflow-x: auto; /* Ensures horizontal scrolling */
}
.region:first-child {
    padding-top: 30px;
}

.region:hover {
    text-decoration-line: underline;
}

.greyBlock .item:hover {
    text-decoration-line: underline;
}

.greyBlock img {
    height: 34px;
}
.lockations{
    max-width: calc(100% - 4px);
    border: 2px solid #eff1f7;
    display: grid;
    grid-template-columns: 9fr 9fr 6fr 3fr 6fr 7fr 15fr;
    width: 90vw;
    margin: 0 auto;
    font-size: 18px;
    background-color: #f8f9fb;
    white-space: normal;
    word-break: break-all;
    border-top: none;
    min-width: 600px;
}
.locations {
    max-width: calc(100% - 4px);
    border: 2px solid #eff1f7;
    display: grid;
    grid-template-columns: 2fr 9fr 6fr 10fr 6fr 6fr 6fr 7fr;
    width: 90vw;
    margin: 0 auto;
    font-size: 18px;
    background-color: #f8f9fb;
    white-space: normal;
    word-break: normal;
    border-top: none;
    min-width: 600px;
}

.lockations:hover {
    border-left: 2px solid #96b7da;
}
.lockationsCol a{
    cursor: pointer;
    text-decoration: none;
    color: #163189;
}
.lockationsCol a:hover{
    text-decoration: underline;
    color: #3a4663;
}
.lock{
    visibility: hidden;

}
.lockationsCol{
    border-left: 2px solid #eff1f7;
    padding: 8px;
    word-wrap: break-word; /* Wraps long words */
    word-break: break-all;
    text-wrap: normal;
    text-align: left;
    font-size: 15px;
    line-height: 1.5;
    color: #1d1d1d;
}

.lockations2 {
    max-width: calc(100% - 4px);
    border: 2px solid #eff1f7;
    display: grid;
    grid-template-columns: 6fr 7fr 5fr 5fr 3fr;
    width: 90vw;
    margin: 0 auto;
    font-size: 18px;
    background-color: #f8f9fb;
    white-space: normal;
    word-break: break-all;
    border-top: none;
    min-width: 600px;
}
.showedLocation {
    position: absolute; /* Вложенный список будет позиционироваться относительно regionContainer */
    top: 100%; /* Располагается ниже родителя */
    left: 0;
    z-index: 10; /* Обеспечивает отображение поверх других элементов */
    background: #f8f9fb; /* Фон списка */
    border: 2px solid #eff1f7;
    border-top: none; /* Убираем верхнюю границу для плавного перехода */
    width: 100%; /* Растягиваем по ширине родителя */
}
.regionContainer {
    position: relative; /* Контейнер для позиционирования вложенного списка */
}
.searchContainer {
    margin: 20px 0;
    text-align: center;
}

.searchInput {
    padding: 10px;
    width: calc(90% - 22px);
    margin: 0 auto;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 10px;
}