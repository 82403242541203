.header {
    width: 90%;
    max-width: 1300px;
    font-size: 18px;
    z-index: 100;
    margin: 0 auto;
}

.rows {

    display: grid;
    margin: 0 auto;
    width: 90%;
    max-width: 1200px;
    padding-bottom: 7px;
}

.upperHeaderRow {
    min-height: 57px;
    max-height: 114px;
    isolation: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

}

.upperHeaderRow:after {
    height: 57px;
}

:root {
    /* цвет темы в RGB формате */
    --red: 200;
    --green: 60;
    --blue: 255;
    /* граничное значение для "светлых" цветов
       рекомендуется 0.5 - 0.6 */
    --threshold: 0.5;
    /* граничное значение для отображения рамки
       рекомендуется 0.8+ */
    --border-threshold: 0.8;
}

.minPros {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
}

.minPros img {
    max-width: 48px;
    max-height: 41px;
}

.minPros a {
    text-decoration-line: none;
    /* расчет воспринимаемой яркости по методу sRGB Luma
    Luma = (red * 0.2126 + green * 0.7152 + blue * 0.0722) / 255 */
    --r: calc(var(--red) * 0.2126);
    --g: calc(var(--green) * 0.7152);
    --b: calc(var(--blue) * 0.0722);
    --sum: calc(var(--r) + var(--g) + var(--b));
    --perceived-lightness: calc(var(--sum) / 255);

    /* черный или белый цвет */
    color: hsl(0, 0%, calc((var(--perceived-lightness) - var(--threshold)) * -10000000%));
    font-family: "Proxima Nova Lt", serif;
    font-size: 16px;
    text-shadow: 1px 1px 1px #000000;
    cursor: pointer;
}

.vision {

    fill: white;
    color: white;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    background-color: #2468b2;
    border-radius: 10px;
    height: 38px;
    width: 270px;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
}

.vision:hover {
    fill: white;
    color: white;
    background-color: #054982;
}

.logReg {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.newRegistration a:hover {
    text-decoration-line: none;
    fill: white;
    color: white;
    background-color: #054982;
    border-color: #054982;
}

.newRegistration a {
    color: white;
    fill: white;
    background-color: #2468b2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-items: center;
    height: 38px;
    width: 165px;
    border-radius: 10px;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    text-decoration-line: none;
}

.smallRegistration a:hover {
    text-decoration-line: none;
    fill: white;
    color: white;
    background-color: #054982;
    border-color: #054982;
}

.smallRegistration a {
    color: white;
    fill: white;
    background-color: #2468b2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-items: center;
    height: 38px;
    width: 38px;
    border-radius: 10px;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    text-decoration-line: none;
}

.registrationLogin {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    height: 38px;
    width: 30%;
    max-width: 300px;
    justify-content: space-around;
}


.anotherLogin a {
    display: inline-block;
    border: 1px solid;
    padding: 6px 15px;
    border-radius: 10px;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    color: white;
    fill: white;
    background-color: #2468b2;
    text-decoration-line: none;
}

.anotherLogin a:hover {
    fill: white;
    color: white;
    background-color: #054982;

}

.smallLogin a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-items: center;
    border: 1px solid;
    height: 38px;
    width: 38px;
    border-radius: 10px;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    color: white;
    fill: white;
    background-color: #2468b2;
    text-decoration-line: none;
}

.smallLogin:hover {
    fill: white;
    color: white;
    background-color: #054982;
}

.bottomHeaderRow {
    border-radius: 20px;
    max-width: 1200px;
    display: grid;
    grid-template-areas: "one two two"
                        "one four four";
    background-color: white;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1) !important;
}

.ppms {
    display: flex;
    grid-area: one;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.ppms a img {
    max-width: 300px;
    cursor: pointer;
}

.obrashenie {
    grid-area: two;
    display: flex;
    justify-content: right;
    gap: 30px;
    height: 64px;
    width: 90%;
    align-items: center;
}

.obrashenie .btn a {
    fill: #054982;
    color: #054982;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    padding: 10px 12px;
    width: 90%;
    max-height: 24px;
    max-width: 200px;
    box-shadow: 0 4px 11px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    text-decoration-line: none;
}

.obrashenie .btn:hover a {
    fill: white;
    color: white;
    background-color: #2468b2;
}

.navBar {
    grid-area: four;
    height: 60px;
    display: flex;
    width: 90%;
    justify-content: space-between;

}

.navBar li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
}

.menuNavBar li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
}

.navBar .btn a {
    fill: #054982;
    color: #054982;
    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: space-around;
    cursor: pointer;
}

.navBar .btn:hover a {
    text-decoration-line: underline;
}

.navBar .btn a {
    fill: #054982;
    color: #054982;
    text-decoration: none;
}

.navBar .btn:hover a {
    fill: #054982;
    color: #054982;
    text-decoration: underline;
}

.menuNavBar {
    grid-area: three;
    width: 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
}

.menuBtn {
    fill: #054982;
    color: #054982;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    cursor: default;

}

.menuBtn a {
    text-decoration-line: none;
    color: #054982;
    fill: #054982;
    cursor: default;
}


.bigMenu ul li {
    padding: 3px;
    list-style-type: none;
    font-size: 14px;
}


.navBar > li:hover .bottomHeaderRow {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

}


.subMenuList {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 20px 25px 10px;
    background: white;
    height: 410px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    width: calc(100% - 50px);
    max-width: 1120px;
    position: relative;
    z-index: 8;
}

.subMenuList a {
    color: #054982;
    text-decoration-line: none;
}

.subMenuList a:hover {
    text-decoration-line: underline;
}

.subMenuList li {
    list-style: none;
    font-size: 17px;
    margin-bottom: 12px;
    margin-right: 12px;
}

.subMenuList .title {
    font-size: 24px;
    margin-bottom: 18px;
    white-space: normal;
    overflow-wrap: normal;
}

.back {
    background-color: #ECECEC;
    height: 50px;
}

.body {
    max-width: 1100px;
    padding-top: 10px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "Segoe UI";
}

.choose span {
    padding: 5px;
    cursor: pointer;
}

.chooseB1 {
    color: white !important;
    background-color: black !important;

}

.chooseB2 {
    color: yellow !important;
    background-color: #1f2433 !important;

}

.chooseB3 {
    color: yellow !important;
}

.chooseB4 {
    color: #063462 !important;
    background-color: #9DD1FF;
}

.default {
    cursor: pointer;
    border-bottom: 1px dotted #000
}


.mobileHeader {
    margin: 0 auto;
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: white;
    display: flex;
    max-width: 1200px;

}

.mobileHeader .menu {
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #96b7da;
    border-top-left-radius: 20px;
    width: 20%;
}

.mobileHeader .pmpk {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobileHeader .pmpk img {
    max-height: 40px;
}

.mobileHeader {
    margin-left: 5%;
    margin-top: 10px;
    width: 90%;

    z-index: 2;
}

.mobileHeader .ppms img {
    height: 60px;
}

.hotBtn a {
    fill: red;
    color: red;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    padding: 10px 12px;
    min-width: 120px;
    box-shadow: 0 4px 11px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    text-decoration-line: none;
    height: 24px;
}

.hotBtn:hover a {
    fill: white;
    color: white;
    background-color: #2468b2;
}

@media screen and (max-width: 1250px) {
    .subMenuList li {
        font-size: 15px;
    }

    .subMenuList .title {
        font-size: 22px;
    }

    .navBar .btn a {
        font-size: 16px;
    }

    .navBar li {
        max-width: 50px;
    }
}

@media screen and (max-width: 1150px) {
    .subMenuList li {
        font-size: 14px;
    }

    .subMenuList .title {
        font-size: 20px;

    }
}

@media screen and (max-width: 1050px) {
    .subMenuList li {
        font-size: 13px;
    }

    .subMenuList .title {
        font-size: 18px;

    }
}

@media screen and (max-width: 999px) {
    .subMenuList {
        height: min-content;
        width: calc(90% - 50px);
        margin: 0 auto;
    }

    .subMenuList li {
        font-size: 17px;
    }

    .subMenuList .title {
        font-size: 24px;

    }
}