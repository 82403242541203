.footer {
    background-color: #f3f4f9;
    width: 90%;
    max-width: 1300px;
    margin: 0 auto;
}

.footer .info {
    padding-top: 20px;
    padding-bottom: 10px;
    width: 90%;
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.footer .info .left {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footer .info .right {
    display: flex;
    flex-direction: row;
}

.footer .info .right a {
    margin-left: 15px;
    color: #2468b2;
    text-decoration-line: none;
    font-size: 16px;
}

.footer .info .right a:hover {
    text-decoration-line: underline;
}

.footer .info .left .upper {
    color: #333333;
    font-size: 12px;
    max-width: 650px;
}

.footer .info .left .upper a {
    color: #333333;
    font-size: 12px;
    text-decoration-line: none;
}

.footer .info .left .upper a:hover {
    text-decoration-line: underline;
}

.footer .info .left .bottom {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-size: 16px;
    color: #333333;
}

.footer .info .left .bottom a img {
    height: 50px;
    cursor: pointer;
}

@media screen and (max-width: 999px){
    .footer {
        width: 100%;
    }
    .footer .info {
        flex-direction: column;
        gap: 20px;
        padding-bottom: 20px;
    }

    .footer .info .left .upper {
        text-align: center;
    }
}