.topSlider .swiper-wrapper {
    width: 100%;
    margin: 0 auto;

    z-index: 1;
}

.topSlider .swiper {
    margin: 0 auto;

    width: 100%;
    z-index: 1;
}

.topSlider .swiper-button-prev {
    left: 50px;
    color: white;
    z-index: 4;
}

.topSlider .swiper-button-next {
    right: 50px;
    color: white;
    z-index: 4;
}

.firstSlide {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/Main/Banner-1.jpg");
    height: 780px;
    background-size: cover;
}

.blog-slider__title {
    width: 80%;
    max-width: 95vw;
    padding-bottom: 15px;
    font-size: 48px;
    color: white;
    text-align: center;
    flex-wrap: wrap;
    text-wrap: normal;
}

.blog-slider__content {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 500px;
}

.blog-slider__text {
    color: white;
    font-size: 22px;
    text-align: center;
    width: 80%;
    max-width: 95vw;
    flex-wrap: wrap;
    text-wrap: normal;
}

.blog-slider__button a{
    color: #054982;
    font-size: 17px;
    fill: #e9f2f9;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    align-items: center;
    text-decoration-line: none;
    width: 100%;
    padding: 4px 8px;
    background-color: white;
    height: 50px;
    box-shadow: 0 4px 11px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    margin-top: 20px;
}

.blog-slider__button a:hover {
    fill: white;
    color: white;
    background-color: #054982;
}

.secondSlide .blog-slider__content {
    height: 300px;
}
.secondSlide .blog-slider__button{
    width: 220px;
}

@media only screen and (max-width: 1299px) {
    .secondSlide {
        width: 100vw;
    }
}

@media screen and (max-width: 700px) {
    .blog-slider__title {
        font-size: 30px;
    }
    .blog-slider__text {
        font-size: 16px;
    }
}

@media screen and (max-width: 420px) {
    .blog-slider__text {
        display: none;
    }
}