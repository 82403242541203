.profileInfo {
    width: 90%;
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 2px solid #dadfe9;
    padding-bottom: 30px;
    margin-bottom: 90px;
}

.contactInfo {
    width: 90%;
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 20px;
}

.profileInfo  h2 {
    padding-top: 0;
    margin-top: 0;
    color: #333333;
    width: 100%;
    font-size: 28px;
}

.contactInfo h2 {
    padding-top: 0;
    margin-top: 0;
    color: #333333;
    width: 100%;
    font-size: 28px;
}

.profileInfo .infoName {
    width: 50%;
    color: #2468b2;
    padding-bottom: 15px;
    font-size: 13px;
}

.contactInfo .infoName {
    width: 100%;
    color: #2468b2;
    padding-bottom: 15px;
    font-size: 13px;
}


.profileInfo input[type=text] {
    width: calc(100% - 8px);
    border: none;
    border-bottom: 1px solid #dadfe9;
    color: #333333;
    font-size: 20px;
    text-align: left !important;
    padding-bottom: 10px;
    margin-bottom: 15px;
    outline: none;
}



.contactInfo input[type=text] {
    width: calc(100% - 8px);
    border: none;
    border-bottom: 1px solid #dadfe9;
    color: #333333;
    font-size: 20px;
    text-align: left !important;
    padding-bottom: 10px;
    margin-bottom: 15px;
    outline: none;
}

.contactInfo .anyInfo {
    flex-basis: 48%;
}

.profileInfo .work {
    flex-basis: 100%;
}
.profileInfo .mail {
    flex-basis: 48%;
}
.profileInfo .photo {
    flex-basis: 48%
}

.profileInfo .photo img {
    border-radius: 100%;
    width: 40px;
    height: 40px;
}

.profileInfo .photo input[type=file] {
    all: initial;

}
.profileInfo .photo input[type=file]::file-selector-button {
    all: initial;
    background-color: #2468b2;
    color: white;
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
}
.profileInfo .photo input[type=file]::file-selector-button:hover {
    background-color: #2468b2;
    color: white;
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
}


.edit {
    max-width: 1170px;
    width: 90%;
    margin: 0 auto;
    height: 28px;
    padding-bottom: 20px;
}

.edit div {
    padding: 6px 20px;
    width: 120px;
    background-color: #808080;
    text-decoration-line: none;
    color: white;
    font-size: 18px;
    border-radius: 8px;
    cursor: pointer;
}

.edit div:hover {
    background-color: rgb(176,176,176);
}

.cancel {
    height: 28px;
}

.cancel div {
    height: 100%;
    padding: 6px 20px;
    background-color: #808080;
    text-decoration-line: none;
    color: white;
    font-size: 18px;
    border-radius: 8px;
    cursor: pointer;
}

.cancel div:hover {
    background-color: rgb(176,176,176);
}

.editMode {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    justify-content: flex-start;
    align-items: flex-start;
}

.editMode .submit button[type=submit] {
    border: none;
    background-color: #2468b2;
    color: white;
    width: 150px;
    border-radius: 8px;
    padding: 9px 20px;
    font-size: 18px;
    font-weight: normal;
}

.navigator {
    width: 90%;
    max-width: 1300px;
    margin: 0 auto;
    margin-bottom: 30px;
    display: flex;
    border-bottom: 1px solid #dadfe9;
}

.navigator a {
    display: flex;
    font-size: 20px;
    flex-direction: row;
    padding: 20px 25px;
    color: #2468b2;
    text-decoration-line: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.navigator a:hover {
    background-color: #eef0f7;
}
.table {
    margin: 0 auto;
    width: 90%;
    max-width: 1200px;
    overflow-x: auto;
}
.main {
    position: relative;
    height: 100%;
    max-width: 1300px;
    background-color: white;
    margin: 0 auto;
    width: 90%;
    z-index: 1;
    min-height: calc(100vh - 302px);
}

.main h1 {
    padding-top: 30px;
    padding-bottom: 20px;
    font-size: 34px;
    color: #2468b2;
    width: 90%;
    margin: 0 auto;
    max-width: 1170px;
}

.reportForm {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}
.reportForm a {
    padding: 10px 15px;
    text-decoration: none;
    color: #2468b2;
    border: 1px solid #2468b2;
    display: inline-block;
    text-align: center;
    border-radius: 10px;
}
.reportForm input {
    padding: 8px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #c0c4c9;
    font-family: "Tahoma", "Arial";
}
.reportForm button[type="submit"]:disabled {
    cursor: unset;
}
.message {
    display: flex;
    justify-content: center;
    padding-top: 30px;
}
.wrong {
    padding-bottom: 15px;
    color: red;
}

.reportForm label {
    width: 100%;
    color: #2468b2;
    padding-bottom: 10px;
    font-size: 16px;
}

.reportForm input[type=number] {
    padding: 8px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #c0c4c9;
    margin-bottom: 15px;
    font-family: "Tahoma", "Arial";
}
.reportForm input[type=text] {
    padding: 8px;
    min-height: 100px;
    width: calc(100% - 16px);
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #c0c4c9;
    margin-bottom: 15px;
    font-family: "Tahoma", "Arial", "Arabic Transparent";
}
.reportForm select {
    padding: 8px;
    min-height: 37px;
    width: calc(100% - 16px);
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #c0c4c9;
    margin-bottom: 15px;
    font-family: "Tahoma", "Arial", "Arabic Transparent";
}

.reportForm textarea {
    padding: 8px;
    resize: vertical;
    width: calc(100% - 16px);
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #c0c4c9;
    margin-bottom: 15px;
    font-family: "Tahoma", "Arial", "Arabic Transparent";
}

.enabled button[type=submit] {
    margin-top: 20px;
    border-radius: 10px;
    font-size: 16px;
    background-color: #2468b2;
    color: white;
    transition: background-color 0.3s ease-in-out;
}
.enabled button[type=submit] {
    margin-top: 20px;
    border-radius: 10px;
    font-size: 16px;
    background-color: #2468b2;
    color: white;
    transition: background-color 0.3s ease-in-out;
}

.disabled input[type=submit] {
    margin-top: 20px;
    border-radius: 10px;
    font-size: 16px;
    background-color: #2468b2;
    color: white;
    cursor: not-allowed;
}

.enabled input[type=submit]:hover {
    background-color: #054982;
}

.controlIconClose {
    display: none;
}

details[open] .controlIconClose {
    display: initial;
    transition: .3s ease;
}

details[open] .controlIconExpand {
    display: none;
}
summary::-webkit-details-marker {
    display: none
}

.formDetails {
    width: 90%;
    margin: 0 auto;
}

.formDetails summary {
    font-weight: 300;
    color: #2468b2;
    font-size: 30px;
    padding-bottom: 20px;
    cursor: pointer;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.addForm {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
    margin-top: 10px;
}

.addForm a {
    background-color: #2468b2;
    font-size: 24px;
    color: white;
    text-decoration-line: none;
    padding: 11px 18px;
    border-radius: 10px;
}

.addFormField {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.addFormField .addFormInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 10px;
    padding-bottom: 20px;
}
.addFormField .addFormInfo input {
    padding: 8px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #c0c4c9;
    font-family: "Tahoma", "Arial";
}
.addFormField .addFormInfo select {
    padding: 8px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #c0c4c9;
    font-family: "Tahoma", "Arial";
}

.addFormField .addFormInfo button {
    padding: 8px;
    font-size: 16px;
    background-color: white;
    border: 1px solid #c0c4c9;
    border-radius: 5px;
}

.reportForm button[name="add"] {
    border: 1px solid #c0c4c9;
    height: 38px;
    border-radius: 10px;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;
}
.reportForm button[type="submit"] {
    border: 1px solid ;
    height: 38px;
    border-radius: 10px;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    color: white;
    fill: white;
    background-color: #2468b2;
}
.reportForm button[type="button"] {
    height: 38px;
    border-radius: 10px;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
}
.addFormField .addFormInfo input {
    width: 50%;
}

.addFormField .addFormInfo input[id="short"] {
    width: 10%;
}
.addFormField .addFormInfo input[id="number"] {
    width: 10%;
}
.addFormField .addFormInfo input[id="comm"] {
    width: 90%;
}
.addFormField .addFormInfo button {
    width: 5%;
}

.addFormField .addFormInfo select {
    width: 15%;
}

.fBut {
    border: none;
    border: 1px solid #0056b3;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 10px;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    margin-bottom: 10px;

    text-align: center;
}

.fBut a {
    text-decoration-line: none;
    color: #0056b3;
}

.check {
    min-width: 25%;
    display: flex;
    column-gap: 20px;
    align-items: center;
}
.check input {
    height: 30px;
}
.check label {
    padding-bottom: 0px;
}

.reportList {
    width: 90%;
    margin: 0 auto;
}
.reportList a {
    font-size: 24px;
    color: #2468b2;
    text-decoration-line: none;

}
.reportList a:hover {
    font-size: 24px;
    text-decoration-line: underline;
}

@media screen and (max-width: 999px) {

    .addFormField .addFormInfo input[id="number"] {
        width: 100%;
    }
    .addFormField .addFormInfo input {
        width: 100%;
    }

    .addFormField .addFormInfo input[id="short"] {
        width: 100%;
    }

    .addFormField .addFormInfo select {
        width: 100%;
    }

    .addFormField .addFormInfo button {
        width: 10%;
    }
}

.instruction {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #e9ecef;
}

.instruction h3 {
    color: #2468b2;
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 20px;
}

.instruction p {
    margin: 10px 0;
    line-height: 1.5;
    color: #333;
}

.templateLink {
    color: #2468b2;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
}

.templateLink:hover {
    text-decoration: underline;
}

.checkboxGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0;
}

.checkboxGroup label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: normal;
}

.checkboxGroup input[type="checkbox"] {
    margin: 0;
    width: auto;
}

.description {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 1.5;
}

.fieldNumber {
    font-weight: bold;
}

.required {
    color: red;
    margin-left: 5px;
}