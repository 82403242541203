.mobileComics img{
    width: 100%;
}

.mobileComics {
    margin: 0 auto;
    width: 90%;

}

.item {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.item img {
    width: 48%;
}


.innerCarousel {
    display: flex;
}

.carousel {
    overflow: hidden;
    margin: 0 auto;
    padding-bottom: 20px;
}

.newsText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    width: 50%;
    max-width: 50%;
}



.newsTitle a {
    height: 120px;
    max-height: 120px;
    color: #005090;
    font-size: 28px;
    text-decoration-line: none;
    width: 100%;
    overflow: hidden;
    text-wrap: normal;
    display: flex;
}

.newsTitle a:hover {
    text-decoration-line: underline;
}

.newsDate {
    height: 15%;
    font-size: 14px;
    color: #00000080
}

.newsDescription {
    height: 73px;
    font-size: 17px;
    color: #333333;
    overflow: hidden;
    text-wrap: normal;
    padding-bottom: 10px;
}

.btn a {
    fill: #054982;
    color: #054982;
    display: inline-block;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    padding: 10px 12px;
    max-height: 24px;

    box-shadow: 0 4px 11px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    text-decoration-line: none;
    background-color: white;
}

.btn:hover a {
    fill: white;
    color: white;
    background-color: #2468b2;
}

@media screen and (max-width: 1000px) {
    .item img {
        width: calc(100% - 10px);
        padding: 5px;
    }

    .newsTitle a {
        width: calc(100% - 20px);
        margin-top: 10px;
        margin-left: 5px;
        height: 75px;
        font-size: 17px;
        text-align: left;
        margin-bottom: 5px;
    }

    .newsText {
        max-width: 100%;
        width: 100%;
        height: auto;
    }

    .newsText {
        max-height: 400px;
        min-height: 0;
    }

    .newsDate {
        max-height: 40px;
        width: calc(100% - 10px);
        margin: 0 auto;
        padding-bottom: 10px;
    }

    .newsDescription {
        width: calc(100% - 10px);
        margin: 0 auto;
        max-height: 120px;
        padding-bottom: 10px;
    }

}