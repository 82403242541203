.main .filePage .leftSide h2 {
    font-size: 38px;
    color: #2468b2;
    width: 100%;
    text-wrap: normal;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
}

.buttonsList:last-child {
    border-bottom: none;

}



.buttonsList:first-child {
    border-top: none;
}

.main {
    position: relative;
    height: 90%;
    max-width: 1300px;
    background-color: white;
    margin: 0 auto;
    z-index: 1;
    width: 90%;
}

.filePage {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    max-width: 1300px;
    padding-bottom: 30px;
}

.filePage h2 {
    font-size: 38px;
    color: #2468b2;
    width: 100%;
    text-wrap: normal;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
}

.leftSide {
    width: 60%;
}


.rightSide {
    width: 30%;
}

@media screen and (max-width: 1000px) {
    .leftSide {
        width: 90%;
    }
    .rightSide {
        width: 90%;
    }
    .infoPanel {
        width: 100%;
        margin: 10px;
    }
}

.buttonClass {
    width: calc(100% - 0px);
    border-bottom: 1px solid #dadfe9;
}

.buttonClass a{
    background-color: #f8f9fb;
    border-left: 1px solid #f8f9fb;
    width: calc(100% - 40px);
    color: #2468b2;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 20px;
    text-decoration-line: none;
    padding: 20px;
    min-width: calc(100% - 40px);
}

.buttonClass a:hover {
    text-decoration-line: underline;
    border-left: 1px solid #2468b2;
}

.pdfStyle {
    color: #2468b2;
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    padding: 10px;
}

.infoPanel {
    margin-top: 30px;
    display: block;
    border-radius: 14px;
    background-color: #f3f4f9;
    height: min-content;
    padding: 10px;
}

.infoPanel .navRef a{
    padding: 5px 10px;
    display: inline-block;
    vertical-align: center;
    align-items: center;
    border-radius: 14px;
    font-size: 17px;
    color: #2468b2;
    fill: #2468b2;
    text-decoration-line: none;
    transition: background-color 0.1s ease-in-out;
    cursor: pointer;
}

.infoPanel .navRef a:hover {
    background-color: #0056b3;
    color: white;
    fill: white;
}

.infoPanel .navRef a svg {
    padding-right: 10px;
}