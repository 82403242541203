
.apps {
  background-color: #252d4a;
  width: 100%;
  max-width: 850px;
  min-height: 200px;
  height: min-content;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  margin: 50px auto;
  font-size: 22px;
}
.block{
}

.block img{
  max-width: 1300px;
}

.score-section {
  display: flex;
  font-size: 24px;
  align-items: center;
  color: #ffffff;
}

/* QUESTION/TIMER/LEFT SECTION */
.question-section {
  width: 100%;
  position: relative;
  
}

.question-count {
  margin-bottom: 20px;
  color: #ffffff;
}

.question-count span {
  font-size: 28px;
}

.question-text {
  margin-bottom: 12px;
  padding-left: 30px;
  padding-right: 50px;
  color: #ffffff;
}

.timer-text {
  background: rgb(230, 153, 12);
  padding: 15px;
  margin-top: 20px;
  margin-right: 20px;
  border: 5px solid rgb(255, 189, 67);
  border-radius: 15px;
  text-align: center;
}

/* ANSWERS/RIGHT SECTION */
.answer-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 400px;
}

.button-app {
  width: 100%;
  font-size: 20px;
  color: #ffffff;
  background-color: #252d4a;
  border-radius: 15px;
  display: flex;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  border: 5px solid #234668;
  cursor: pointer;
  text-align: start;
}
.button-app a{
  text-decoration: none;
  color: #ffffff;
}
.button-app-true {
  width: 100%;
  font-size: 20px;
  color: #ffffff;
  background-color: #252d4a;
  border-radius: 15px;
  display: flex;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  border: 5px solid #2f922f;
  cursor: pointer;
  text-align: start;
}
.button-app-true a{
  text-decoration: none;
  color: #ffffff;
}

.correct {
  background-color: #2f922f;
}

.incorrect {
  background-color: #ff3333;
}

button:hover {
  background-color: #555e7d;
}

button:focus {
  outline: none;
}

button svg {
  margin-right: 5px;
}
.blockVic{
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  background-size: cover;
  margin: 10px auto;
  min-height: 500px;
}
.blockVic img{
  max-width: 300px;
}
.apps{
  margin-top: 70px;
  min-height: 300px;
}
@media only screen and (max-width:550px){
  .blockVic{
    display: flex;
    flex-direction: column;
  }
  .apps {
    background-color: #252d4a;
    width: 100%;
    max-width: 850px;
    min-height: 200px;
    height: min-content;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    margin: 50px auto;
    font-size: 22px;
  }
  .button-app {
    width: 300px;
    font-size: 20px;
    color: #ffffff;
    background-color: #252d4a;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
    border: 5px solid #234668;
    cursor: pointer;
    text-align: start;
  }
}