.newsPage {
    max-width: 1300px;
    position: relative;
    background-color: white;
    margin: 0 auto;
    height: 100%;
    width: 90%;
}

.newsList {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    gap: 30px;
    padding-bottom: 20px;
    margin: 0 auto;
}

.newsPage h1 {
    padding-top: 30px;
    padding-bottom: 20px;
    font-size: 38px;
    color: #2468b2;
    width: 90%;
    margin: 0 auto;
    max-width: 1250px;
}

.newsSquare {
    flex-basis: calc(33% - 20px);
    display: block;
    transition: all 0.3s ease;
}

.newsSquare .newsImg {
    height: 225px;
    width: 100%;
}

.newsSquare .newsImg img{

    width: 100%;
    height: 225px;
}



.newsSquare:hover {
    transform: scale(1.05);
}


.newsSquare .newsTitle a{
    padding-top: 5px;
    font-size: 20px;
    color: #2468b2;
    text-decoration-line: none;
    max-width: 400px;
    min-width: 160px;
    max-height: 65px;
    display: block;
}

.newsSquare .newsTitle {
    display: inline-block;
    max-height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.newsSquare .description {
    color: #333333;
    font-size: 17px;
    overflow: hidden;
    text-wrap: normal;
    margin-top: 7px;
    height: 51px;
}

.newsSquare .date {
    color: #484848;
    font-size: 14px;
}

.newsSquare .type {
    padding-left: 36px;
    color: #054982;
    font-size: 14px;
}

.newsSquare .btn a {
    fill: #054982;
    color: #054982;
    display: inline-block;
    border-radius: 10px;
    padding: 10px 12px;
    margin-top: 10px;
    box-shadow: 0 4px 11px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    text-decoration-line: none;
    background-color: white;
}

.newsSquare .btn:hover a {
    fill: white;
    color: white;
    background-color: #2468b2;
}

.websSquare {

}

@media screen and (max-width: 1313px) {
    .newsSquare {
        flex-basis: calc(50% - 15px);
    }
    .newsSquare .newsImg {
        height: 300px;
    }
    .newsSquare .newsImg img{
        height: 300px;
    }
}

@media screen and (max-width: 999px) {
    .newsSquare {
        flex-basis: 100%;
    }
    .newsSquare .newsImg {
        height: auto;
    }
    .newsSquare .newsImg img{
        height: auto;
    }
}